import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "odyssey-dance-theatre",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#odyssey-dance-theatre",
        "aria-label": "odyssey dance theatre permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Odyssey Dance Theatre`}</h1>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publication.`}</p>
    </blockquote>
    <h2>Tonight’s Show</h2>
    {
      /* ***************CURRENT PUBLICATIONS****************/
    }
    <Link to="/slc-thriller-2022" alt="Link to Odyssey Dance Theatre Thriller in Salt Lake City Utah digital publication." mdxType="Link">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAVJ13FFpIi53QaFUMBT/AP/EABwQAAICAwEBAAAAAAAAAAAAAAECAAMEESETM//aAAgBAQABBQLQINZUmJQXVqLGl3Lcf4FpkHd6OVHq0bp//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAhD/2gAIAQMBAT8BZRbnP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASH/xAAdEAACAgIDAQAAAAAAAAAAAAAAAgEhETESIjJh/9oACAEBAAY/AqO2jRySGkjpUE4Xj8FNMMU57LnJ/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUYGh/9oACAEBAAE/IQOiJuAemStsteKkMkeKlSy2UyCZlQsB25Ca3uRm9fiy43eYE0eW4jXj9MTZb9n/2gAMAwEAAgADAAAAEBjHv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QyJyRs4zJv//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxAjJDGX/8QAHRABAAMBAQADAQAAAAAAAAAAAQARITFBgZGhwf/aAAgBAQABPxD2waAjOewoohCXtcFLjC04rHcu4piUcpel3MXMe6gAFE4/qo/MuCDlUZgRl1ftKaogcp4yL6l4OL6WH5L7UNV/2f/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/88a99382a75008b37446bf2410fabd2d/8ac56/thriller-logan-2022-cover.webp 240w", "/static/88a99382a75008b37446bf2410fabd2d/d3be9/thriller-logan-2022-cover.webp 480w", "/static/88a99382a75008b37446bf2410fabd2d/87ca7/thriller-logan-2022-cover.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/88a99382a75008b37446bf2410fabd2d/09b79/thriller-logan-2022-cover.jpg 240w", "/static/88a99382a75008b37446bf2410fabd2d/7cc5e/thriller-logan-2022-cover.jpg 480w", "/static/88a99382a75008b37446bf2410fabd2d/1a144/thriller-logan-2022-cover.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/88a99382a75008b37446bf2410fabd2d/1a144/thriller-logan-2022-cover.jpg",
              "alt": "ODT Thriller in Logan Cover",
              "title": "ODT Thriller in Logan Cover",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <div className="homeButtons">Thriller - SLC</div>
    </Link>
    <section className="home-list">
      <Link to="/ogden-thriller-2022" alt="Link to Odyssey Dance Theatre Thriller in Logan Utah digital publication." className="current" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAVJ13FFpIi53QaFUMBT/AP/EABwQAAICAwEBAAAAAAAAAAAAAAECAAMEESETM//aAAgBAQABBQLQINZUmJQXVqLGl3Lcf4FpkHd6OVHq0bp//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAhD/2gAIAQMBAT8BZRbnP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASH/xAAdEAACAgIDAQAAAAAAAAAAAAAAAgEhETESIjJh/9oACAEBAAY/AqO2jRySGkjpUE4Xj8FNMMU57LnJ/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUYGh/9oACAEBAAE/IQOiJuAemStsteKkMkeKlSy2UyCZlQsB25Ca3uRm9fiy43eYE0eW4jXj9MTZb9n/2gAMAwEAAgADAAAAEBjHv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QyJyRs4zJv//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxAjJDGX/8QAHRABAAMBAQADAQAAAAAAAAAAAQARITFBgZGhwf/aAAgBAQABPxD2waAjOewoohCXtcFLjC04rHcu4piUcpel3MXMe6gAFE4/qo/MuCDlUZgRl1ftKaogcp4yL6l4OL6WH5L7UNV/2f/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/88a99382a75008b37446bf2410fabd2d/8ac56/thriller-logan-2022-cover.webp 240w", "/static/88a99382a75008b37446bf2410fabd2d/d3be9/thriller-logan-2022-cover.webp 480w", "/static/88a99382a75008b37446bf2410fabd2d/87ca7/thriller-logan-2022-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/88a99382a75008b37446bf2410fabd2d/09b79/thriller-logan-2022-cover.jpg 240w", "/static/88a99382a75008b37446bf2410fabd2d/7cc5e/thriller-logan-2022-cover.jpg 480w", "/static/88a99382a75008b37446bf2410fabd2d/1a144/thriller-logan-2022-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/88a99382a75008b37446bf2410fabd2d/1a144/thriller-logan-2022-cover.jpg",
                "alt": "ODT Thriller in Logan Cover",
                "title": "ODT Thriller in Logan Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Thriller - Ogden</div>
      </Link>
      <Link to="/logan-thriller-2022" alt="Link to Odyssey Dance Theatre Thriller in Logan Utah digital publication." className="current2" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAVJ13FFpIi53QaFUMBT/AP/EABwQAAICAwEBAAAAAAAAAAAAAAECAAMEESETM//aAAgBAQABBQLQINZUmJQXVqLGl3Lcf4FpkHd6OVHq0bp//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAhD/2gAIAQMBAT8BZRbnP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASH/xAAdEAACAgIDAQAAAAAAAAAAAAAAAgEhETESIjJh/9oACAEBAAY/AqO2jRySGkjpUE4Xj8FNMMU57LnJ/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUYGh/9oACAEBAAE/IQOiJuAemStsteKkMkeKlSy2UyCZlQsB25Ca3uRm9fiy43eYE0eW4jXj9MTZb9n/2gAMAwEAAgADAAAAEBjHv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QyJyRs4zJv//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxAjJDGX/8QAHRABAAMBAQADAQAAAAAAAAAAAQARITFBgZGhwf/aAAgBAQABPxD2waAjOewoohCXtcFLjC04rHcu4piUcpel3MXMe6gAFE4/qo/MuCDlUZgRl1ftKaogcp4yL6l4OL6WH5L7UNV/2f/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/88a99382a75008b37446bf2410fabd2d/8ac56/thriller-logan-2022-cover.webp 240w", "/static/88a99382a75008b37446bf2410fabd2d/d3be9/thriller-logan-2022-cover.webp 480w", "/static/88a99382a75008b37446bf2410fabd2d/87ca7/thriller-logan-2022-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/88a99382a75008b37446bf2410fabd2d/09b79/thriller-logan-2022-cover.jpg 240w", "/static/88a99382a75008b37446bf2410fabd2d/7cc5e/thriller-logan-2022-cover.jpg 480w", "/static/88a99382a75008b37446bf2410fabd2d/1a144/thriller-logan-2022-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/88a99382a75008b37446bf2410fabd2d/1a144/thriller-logan-2022-cover.jpg",
                "alt": "ODT Thriller in Logan Cover",
                "title": "ODT Thriller in Logan Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Thriller - Logan</div>
      </Link>
    </section>
    <br />
    <hr />
    {
      /* ***************Past PUBLICATIONS*************** */
    }
    <h2>Past Publications</h2>
    <section className="home-list">
      <Link to="/suad-2022" alt="Link to Odyssey Dance Theatre Shut Up and Dance digital publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAeWlrRc4YQxxDZGl3//EABsQAAICAwEAAAAAAAAAAAAAAAECABIDERMQ/9oACAEBAAEFAhhaHGwjipxsu2rV67UGMRqdDzYg+f/EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/ASP/xAAXEQADAQAAAAAAAAAAAAAAAAABAiBR/9oACAECAQE/AVOx/8QAHxAAAQMDBQAAAAAAAAAAAAAAAAERMRAhQRMiQlFh/9oACAEBAAY/Ak9MEmYIVyw/HsstNN9okJT/xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhMUEQUXFh/9oACAEBAAE/ISRjVipkjBUEDWoSKCAAb24b2w/PsI86gqUhvE3ffZ412zxAKBAVDMQ7n//aAAwDAQACAAMAAAAQX+yM/8QAGREAAgMBAAAAAAAAAAAAAAAAAREAECFR/9oACAEDAQE/EF7AStv/xAAZEQACAwEAAAAAAAAAAAAAAAABEQAQIUH/2gAIAQIBAT8QYMQgPIH2v//EACAQAQACAgICAwEAAAAAAAAAAAEAESExQWFxkRBRgaH/2gAIAQEAAT8QshGwsUeJnSUW0XUEs6NhK6yROy2luCzPbFDcqjyKPpxk6lleDk59wPCwaORrJCVZAos9f34K6QYN93Vw45eR5PcQ0X8n/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4c92cb1d258639654f4861942bec33fe/8ac56/suad-cover.webp 240w", "/static/4c92cb1d258639654f4861942bec33fe/d3be9/suad-cover.webp 480w", "/static/4c92cb1d258639654f4861942bec33fe/87ca7/suad-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4c92cb1d258639654f4861942bec33fe/09b79/suad-cover.jpg 240w", "/static/4c92cb1d258639654f4861942bec33fe/7cc5e/suad-cover.jpg 480w", "/static/4c92cb1d258639654f4861942bec33fe/1a144/suad-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4c92cb1d258639654f4861942bec33fe/1a144/suad-cover.jpg",
                "alt": "Shut Up and Dance Cover",
                "title": "Shut Up and Dance Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Shut Up and Dance</div>
      </Link>
      <Link to="/wonderful-life-2021" alt="Link to Salt Lake City 2021 Wonderful Life digital publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgMABP/aAAwDAQACEAMQAAAB9aSz57bhAmMm6K6BTD//xAAdEAABBAIDAAAAAAAAAAAAAAABAAIRIQMxBBIj/9oACAEBAAEFAptmRuRAwuQfFvUHaIlBlQiyEdAV/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAEDAQE/ASN//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAlH/2gAIAQIBAT8BlRWNl1w//8QAHBAAAgICAwAAAAAAAAAAAAAAAAERMQIhAxAg/9oACAEBAAY/At0NJ9ZI43jMFmmJKEX4/8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFBUWGRscH/2gAIAQEAAT8hyqY7jSCmxBhkCoo11FoKP1+3L5EXBD2MQQCFOB8TD3NcKrxHeVT/2gAMAwEAAgADAAAAEHg/Av/EABkRAQEAAwEAAAAAAAAAAAAAAAEAESFBMf/aAAgBAwEBPxA0sOwHGQz7f//EABoRAQADAAMAAAAAAAAAAAAAAAEAESExQaH/2gAIAQIBAT8QqivEqwZRncGM9T//xAAcEAEBAQADAAMAAAAAAAAAAAABEQAhMUFRYfH/2gAIAQEAAT8QW6AK29Y0QiGk/MFCftkz4VF4EXS9RqwAFZ6rs/Gvox8TXWzj3Y+QWTwyjiHt9YmF5tj1nEOjMrErqS7/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5035cffc5396368f9e476b99b8de194d/8ac56/wonderful-cover.webp 240w", "/static/5035cffc5396368f9e476b99b8de194d/d3be9/wonderful-cover.webp 480w", "/static/5035cffc5396368f9e476b99b8de194d/87ca7/wonderful-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5035cffc5396368f9e476b99b8de194d/09b79/wonderful-cover.jpg 240w", "/static/5035cffc5396368f9e476b99b8de194d/7cc5e/wonderful-cover.jpg 480w", "/static/5035cffc5396368f9e476b99b8de194d/1a144/wonderful-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5035cffc5396368f9e476b99b8de194d/1a144/wonderful-cover.jpg",
                "alt": "It's a Wonderful Life",
                "title": "It's a Wonderful Life",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">It’s a Wonderful Life</div>
      </Link>
      <Link to="/slc-thriller2021" alt="Link to Salt Lake City 2021 Thriller digital publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAwAB/9oADAMBAAIQAxAAAAHm6MuoijnFCqTFGQd3/8QAHxAAAgEEAgMAAAAAAAAAAAAAAAECAxITIQQRFDFB/9oACAEBAAEFAqbMkkeQy8fIJSXeyLtdR5J/PQ3v/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEhP/2gAIAQMBAT8BojNT/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAS/9oACAECAQE/ASn/AP/EAB8QAAEDAwUAAAAAAAAAAAAAAAABETECECEDEyIykf/aAAgBAQAGPwJOWTJJ0QRdvTaGYjyzsLUzE3//xAAeEAEAAgEEAwAAAAAAAAAAAAABABEhMUFhsVGRof/aAAgBAQABPyFaogdQReFacynD6QOHsmHILUEaB8RwNaIpkNN0y8BbYlrEpW08QthnxP/aAAwDAQACAAMAAAAQix3A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARYf/aAAgBAwEBPxBR1u1trf/EABgRAQEBAQEAAAAAAAAAAAAAAAEAETEh/9oACAECAQE/EPYWXewAYX//xAAfEAEAAwACAQUAAAAAAAAAAAABABEhMWFRQXGBkbH/2gAIAQEAAT8QSz8uFgCH6la6hTtIc1vXJRbU80+rrmArEQ035WJ29wmfhBoUWVoeYRxP0DFVKQMKg+9/kRKM1V71xLtKNVajrZ//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30710dacc78d819050a2185da8d29d7d/8ac56/thriller-cover-slc.webp 240w", "/static/30710dacc78d819050a2185da8d29d7d/d3be9/thriller-cover-slc.webp 480w", "/static/30710dacc78d819050a2185da8d29d7d/87ca7/thriller-cover-slc.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30710dacc78d819050a2185da8d29d7d/09b79/thriller-cover-slc.jpg 240w", "/static/30710dacc78d819050a2185da8d29d7d/7cc5e/thriller-cover-slc.jpg 480w", "/static/30710dacc78d819050a2185da8d29d7d/1a144/thriller-cover-slc.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30710dacc78d819050a2185da8d29d7d/1a144/thriller-cover-slc.jpg",
                "alt": "Thriller SLC",
                "title": "Thriller SLC",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Thriller - SLC</div>
      </Link>
      <Link to="/north-thriller2021" alt="Link to Logan/Ogden 2021 Thriller digital publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAwAB/9oADAMBAAIQAxAAAAHm6MuoijnFCqTFGQd3/8QAHxAAAgEEAgMAAAAAAAAAAAAAAAECAxITIQQRFDFB/9oACAEBAAEFAqbMkkeQy8fIJSXeyLtdR5J/PQ3v/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQEhP/2gAIAQMBAT8BojNT/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAS/9oACAECAQE/ASn/AP/EAB8QAAEDAwUAAAAAAAAAAAAAAAABETECECEDEyIykf/aAAgBAQAGPwJOWTJJ0QRdvTaGYjyzsLUzE3//xAAeEAEAAgEEAwAAAAAAAAAAAAABABEhMUFhsVGRof/aAAgBAQABPyFaogdQReFacynD6QOHsmHILUEaB8RwNaIpkNN0y8BbYlrEpW08QthnxP/aAAwDAQACAAMAAAAQix3A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARYf/aAAgBAwEBPxBR1u1trf/EABgRAQEBAQEAAAAAAAAAAAAAAAEAETEh/9oACAECAQE/EPYWXewAYX//xAAfEAEAAwACAQUAAAAAAAAAAAABABEhMWFRQXGBkbH/2gAIAQEAAT8QSz8uFgCH6la6hTtIc1vXJRbU80+rrmArEQ035WJ29wmfhBoUWVoeYRxP0DFVKQMKg+9/kRKM1V71xLtKNVajrZ//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30710dacc78d819050a2185da8d29d7d/8ac56/thriller-cover-slc.webp 240w", "/static/30710dacc78d819050a2185da8d29d7d/d3be9/thriller-cover-slc.webp 480w", "/static/30710dacc78d819050a2185da8d29d7d/87ca7/thriller-cover-slc.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30710dacc78d819050a2185da8d29d7d/09b79/thriller-cover-slc.jpg 240w", "/static/30710dacc78d819050a2185da8d29d7d/7cc5e/thriller-cover-slc.jpg 480w", "/static/30710dacc78d819050a2185da8d29d7d/1a144/thriller-cover-slc.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30710dacc78d819050a2185da8d29d7d/1a144/thriller-cover-slc.jpg",
                "alt": "Thriller - Northern Utah",
                "title": "Thriller - Northern Utah",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Thriller - Northern Utah</div>
      </Link>
    </section>
    <br />
    <br />
    <hr />
    <br />
    <p>
  Founded in 1994 and directed by Derryl Yeager, former Ballet West Principal,
  Broadway, Television, and Film veteran, Odyssey Dance Theatre explodes with
  world-class bravado, passionate charm, and choreographic invention, combining
  the classical virtues of ballet with the attack of jazz; the freedom of modern
  dance; the raw energy of hip-hop; the syncopated rhythms of tap; the fluid
  partnering of ballroom; and the spirit of Broadway and Vaudeville to create a
  fresh dance vocabulary and entertainment genre that engages audiences across
  the country and around the world. Odyssey is the “common man’s” dance company,
  with something for everyone to enjoy – not just for those who see dance as an
  “elitist” form of entertainment. The use of contemporary, popular music, along
  with classical, makes it exceptionally appealing to a broad based demographic.
    </p>
    <hr />
    <br />
    <OutboundLink href="http://www.minkycouture.com//?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=odyssey%20dance" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAIEAQMF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABrWRTpk5vOG3E7Awf/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAECERIhA//aAAgBAQABBQLfVWiaPo6qZi0aTqEeM5yuDjT/xAAXEQEBAQEAAAAAAAAAAAAAAAABABAh/9oACAEDAQE/AQwbl//EABcRAAMBAAAAAAAAAAAAAAAAAAAQIRH/2gAIAQIBAT8B1Up//8QAIRAAAQMBCQAAAAAAAAAAAAAAAQACESEQEiAxMkFRcbH/2gAIAQEABj8C2jpUulV8RawSU0tAygibDzh1Ff/EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVFxYf/aAAgBAQABPyF4IAycKOiwxRTPwhwtOyQwyJ7AoKpncQYKJypKI3uK7hVdHjU//9oADAMBAAIAAwAAABAwD0L/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/EFS64AnP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EEDMlXH/xAAeEAEAAwADAQADAAAAAAAAAAABABEhMUFRYXHB8P/aAAgBAQABPxDuWuXCQ1CDVFIPkLv6is2FADR3WvyYwjENeKrLP3EpYeCkilDgcFrv8ZMU0Epx/qhRp8zqJrrC1LllLScYPiW+5P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/985b4def09e3a0dc438fc9bce515c0a1/8ac56/minky.webp 240w", "/static/985b4def09e3a0dc438fc9bce515c0a1/d3be9/minky.webp 480w", "/static/985b4def09e3a0dc438fc9bce515c0a1/87ca7/minky.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/985b4def09e3a0dc438fc9bce515c0a1/09b79/minky.jpg 240w", "/static/985b4def09e3a0dc438fc9bce515c0a1/7cc5e/minky.jpg 480w", "/static/985b4def09e3a0dc438fc9bce515c0a1/1a144/minky.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/985b4def09e3a0dc438fc9bce515c0a1/1a144/minky.jpg",
              "alt": "Minky Couture blankets",
              "title": "Minky Couture blankets",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      